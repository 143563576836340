import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';

const RCC = () => (
    
    <ComponentWrapper>
        <StudyDesignOverview title="Study Overview" description="Phase 3, multicenter, open-label, randomized trial vs sunitinib<sup>2</sup>" data={[
            [
                {
                    title: 'Patient Population',
                    description: `
                        <p>Patients who had not received systemic therapy for advanced RCC (N=861).</p> 
                    `,
                    type: 'box-gradient',
                    colspan: 2
                }
            ],
            [
                {
                    type: 'arrow-gray',
                    colspan: 2
                }
            ],
            [
                {
                    title: 'Randomized (1:1)',
                    colspan: 2
                }
            ],
            [
                {
                    type: 'arrow-gray'
                },
                {
                    type: 'arrow-gray'
                }
            ],
        ]} />
    
        <StudyDesignArms title="Study Arms" arms={[
            {
                title: "KEYTRUDA",
                data: [
                    [
                        {
                            title: 'Initial Treatment',
                            description: `KEYTRUDA 200&nbsp;mg IV Q3W + axitinib 5&nbsp;mg orally bid (n=432)`,
                            type: 'box-green'
                        }
                    ],
                    [
                        {
                            type: 'arrow-green'
                        }
                    ],
                    [
                        {
                            title: 'Treatment Duration',
                            description: `Treatment continued until RECIST v1.1-defined progression of disease or unacceptable toxicity or, for KEYTRUDA, a maximum of 24 months.`
                        }
                    ]
                ]
            },
            {
                title: "Sunitinib",
                data: [
                    [
                        {
                            title: 'Initial Treatment',
                            description: `
                                <p>Sunitinib 50&nbsp;mg orally, once daily for 4 weeks and then off treatment for 2 weeks (n=429)</p>
                            `
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray'
                        }
                    ],
                    [
                        {
                            title: 'Treatment Duration',
                            description: `Treatment was continued until disease progression or development of unacceptable toxic effects.<sup>2</sup>`
                        }
                    ]
                ]
            }
        ]} />

        <StudyCopyBlock 
            title="End Points"
            description={`
                <p>Main efficacy outcome measures: OS and PFS as assessed by blinded independent central review (BICR) according to modified RECIST v1.1
                <p>Additional efficacy outcome measure: ORR as assessed by BICR</p> 
                <p>In the initial analysis, the median follow-up time was 12.8 months (range 0.1–22.0 months)<sup>2</sup></p>
                <p>In the final prespecified analysis, the median follow-up time was 42.8 months (range: 35.6–50.6 months)<sup>1</sup></p>
            `} 
        />

        <StudyCopyBlock 
            title="Dosing Adjustments"
            description={`
                
                <p><strong>Patients were randomized (1:1) to one of the following treatment arms:</strong></p>
                
                <ul>
                    <li>KEYTRUDA 200&nbsp;mg intravenously every 3 weeks up to 24 months in combination with axitinib 5&nbsp;mg orally, twice daily.
                        <ul>
                            <li>Patients who tolerated axitinib 5&nbsp;mg twice daily for 2 consecutive cycles (6 weeks) could increase to 7&nbsp;mg and then subsequently to 10&nbsp;mg twice daily.</li>
                            <li>Axitinib could be interrupted or reduced to 3&nbsp;mg twice daily and subsequently to 2&nbsp;mg twice daily to manage toxicity.</li>
                        </ul>
                    </li>
                    <li>Sunitinib 50&nbsp;mg orally, once daily for 4 weeks and then off treatment for 2 weeks.</li>

                </ul>
            `} 
        />

        <StudyCopyBlock 
            title="Study Design Details"
            description={`
                <p>Administration of KEYTRUDA + axitinib was permitted beyond RECIST-defined disease progression if the patient was clinically stable and considered to be deriving clinical benefit by the investigator.</p>
                <p>Patients were enrolled regardless of PD&#8288;-&#8288;L1 tumor expression status.<sup>i</sup></p>
                <p>Patients with active autoimmune disease requiring systemic immunosuppression within the last 2 years were ineligible.</p>
                <p>Assessment of tumor status was performed at baseline, after randomization at week 12, then every 6 weeks thereafter until week 54, and then every 12 weeks thereafter.</p>
            `}
            footnotes={[
                {
                    label:'i.',
                    text: `
                        Randomization was stratified by IMDC risk categories (favorable vs intermediate vs poor) and geographic region (North America vs Western Europe vs rest of the world).
                    `
                }
            ]}
            definitions={`
                IV = intravenously;
                PD&#8288;-&#8288;L1 = programmed death ligand 1;
                Q3W = every 3 weeks;
                bid = twice daily; 
                RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1.
            `} 
        />
 
    </ComponentWrapper>
);

export default RCC;
